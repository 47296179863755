export function debounce(fn) {
    var timeout;
    return function () {
        var context = this;
        var args = arguments;
        if (timeout) {window.cancelAnimationFrame(timeout)}
        timeout = window.requestAnimationFrame(function () {
            fn.apply(this, args);
        })
    }
}
