import Atropos from 'atropos';


var productItems = document.querySelectorAll('.my-atropos');

export function initProductEffects() {

    productItems.forEach(function(product) {
        let myAtropos = Atropos({
            el: product,
            rotateTouch: false
        });
    })

}
