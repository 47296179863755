"use strict";

import { initHeroSlider } from "./imports/sliders";
import { initHeaderOnScroll, initMobileMenu } from "./imports/header";
import { initLightboxGallery } from "./imports/lightbox-gallery";
import { initLogoutButton } from "./imports/various-stuff";
import { initProductEffects } from "./imports/animations";
import { initTimeline } from "./imports/timeline";
import {initCookieNotice} from "./imports/cookie-notice";
import {initAutoComplete} from "./imports/auto-complete";

// Document is ready!
document.addEventListener('DOMContentLoaded', function() {

    initHeroSlider();      // Hero Slider
    initHeaderOnScroll();  // Header effect on scroll
    initMobileMenu();      // Mobile menu functionality
    initLightboxGallery(); // Lightbox Gallery
    initLogoutButton();    // LogOut?!
    initProductEffects();  // So products can fly now!
    initTimeline();        // This year, that year
    initCookieNotice();    // GDPR

});
