export function initLogoutButton() {

    // menu-mobile.blade.php
    var logoutButton = document.querySelectorAll('[data-action="logout"]');
    logoutButton.forEach(function(btn) {
        btn.addEventListener('click', function(e) {
            e.preventDefault();
            document.getElementById('logout-form').submit();
        })
    })

}
