import { debounce } from "./helpers";

// Header element
var headerEl = document.querySelector('[data-el="header"]');

// Menu variables
var menuTrigger = document.querySelectorAll('[data-menu="trigger"]');
var menuOverlay = document.querySelector('[data-menu="overlay"]');
var menuMobile = document.querySelector('[data-menu="mobile"]');

function headerOnScroll() {
    window.pageYOffset > 100 ? headerEl.classList.add('is-scrolled') : headerEl.classList.remove('is-scrolled');
}

function mobileMenuOpen(trigger) {
    trigger.classList.add('is-clicked');
    menuMobile.classList.add('is-visible');
    menuOverlay.style.display = 'block';
}

function mobileMenuClose(trigger) {
    trigger.classList.remove('is-clicked');
    menuMobile.classList.remove('is-visible');
    menuOverlay.style.display = 'none';
}

export function initMobileMenu() {
    if(menuMobile && menuTrigger) {
        menuMobile.style.transition = '0.3s';

        menuOverlay.addEventListener('click', function() {
            menuTrigger.forEach(function(trigger) {
                mobileMenuClose(trigger);
            })
        });

        menuTrigger.forEach(function(trigger) {
            trigger.addEventListener('click', function() {
                trigger.classList.contains('is-clicked') ? mobileMenuClose(trigger) : mobileMenuOpen(trigger);
            })
        })
    }
}

export function initHeaderOnScroll() {
    if(headerEl) {
        var initHOS = debounce(headerOnScroll);
        window.addEventListener('scroll', initHOS);
    }
}
