import $ from 'jquery';
import slick from 'slick-carousel';

export function initTimeline() {

    $.js = function (el) {
        return $('[data-js=' + el + ']')
    };

    $.js('timeline-carousel').slick({
        infinite: false,
        arrows: false,
        dots: true,
        autoplay: false,
        speed: 1100,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });

}

