import Flickity from 'flickity';
require('flickity-imagesloaded');

var heroSliderEl = document.querySelectorAll('[data-slider="hero"]');

export function initHeroSlider() {

    heroSliderEl.forEach(function(slider) {
        if(slider.childElementCount > 1) {
            var flkty = new Flickity(slider, {
                cellSelector: '.heroslider-slide',
                wrapAround: true,
                autoPlay: 2500,
                adaptiveHeight: true,
                watchCSS: false,
                imagesLoaded: true,
                cellAlign: 'left',
                prevNextButtons: true,
                pageDots: false,
                arrowShape: {
                    x0: 20,
                    x1: 65, y1: 45,
                    x2: 70, y2: 40,
                    x3: 30
                }
            });
        }
    })

}
