import lightGallery from "lightgallery";

var galleryEl = document.getElementById('ul-li');

export function initLightboxGallery() {

    if(galleryEl) {
        lightGallery(galleryEl);
    }

}
