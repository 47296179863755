function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

export function initCookieNotice() {
    var cookieNotice = document.querySelector('[data-cookie="notice"]')
    var cookieBtnAccept = document.querySelector('[data-cookie="accept"]')
    var cookieBtnDecline = document.querySelector('[data-cookie="decline"]')
    var cookieBtnDelete = document.querySelector('[data-cookie="delete"]')

    var cookieDisable = document.querySelector('.delete-cookies');
    if(cookieDisable) {
        cookieDisable.addEventListener('click', function() {
            document.cookie = "custom= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
            window.location.reload();
        })
    }

    if(cookieNotice) {

        function showMap() {
        //    if(!getCookie('custom')) {
        //        setCookie('custom', 'map', 180);
        //    }
        //    cookieNotice.style.display = 'none';
        }

        function setCookieforCN() {
            setCookie('custom', 'map', 180);
        }

        function removeCookieforCN() {
            document.cookie = "custom= ; expires = Thu, 01 Jan 1970 00:00:00 GMT"
            window.location.reload();
        }

        function hideCookieNotice() {
            cookieNotice.style.display = 'none';
        }

        function showCookieNotice() {
            cookieNotice.style.display = 'inline-flex';
        }

        cookieBtnDelete.addEventListener('click', function(e) {
            e.preventDefault();
            removeCookieforCN();
        })

        getCookie('custom') ? hideCookieNotice() : showCookieNotice();
        cookieBtnDecline.addEventListener('click', hideCookieNotice);
        cookieBtnAccept.addEventListener('click', hideCookieNotice);
        cookieBtnAccept.addEventListener('click', setCookieforCN);
    }
}
